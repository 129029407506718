import './OrderCard.css';
import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Empanada} from "../../../../Services/EmpanadasService";

type OrderCardProps = {
    empanada: Empanada;
    onCardChange: (empanada: Empanada, dozen: number, units: number) => void;
}

const OrderCard: React.FC<OrderCardProps> = ({empanada, onCardChange}) => {
    const [dozen, setDozen] = useState(0);
    const [units, setUnits] = useState(0);

    useEffect(() => {
        onCardChange(empanada, dozen, units);
    }, [dozen, units]);

    return (
        <Card className="mb-4">
            <Card.Body>
                <div className="d-flex align-items-center mb-4">
                    <Card.Img variant="top" src={empanada.imagePrimary} className="w-25"/>
                    <Card.Title className={"ms-4"}>
                        <span className={empanada.stock == 0 ? "withoutStockTitle" : ""}>{empanada.name}</span>
                        {empanada.dealPrice > 0 && <span className="deal"> ¡OFERTA!</span>}
                        {empanada.stock <= 0 && <span className="withoutStock"> En Producción!</span>}
                        {(
                            (empanada.productType === 'empanada' && empanada.stock > 0 && empanada.stock < 24)
                            || (empanada.productType === 'tarta' && empanada.stock > 0 && empanada.stock < 6)
                        ) && <span className="lowStock"> Quedan pocas!</span>}
                    </Card.Title>
                </div>
                {empanada.productType === 'empanada' && (
                    <Form.Group controlId={`formDozens-${empanada.id}`} className="mb-2">
                        <Form.Label>Docenas</Form.Label>
                        <div className="d-flex align-items-center justify-content-between">
                            <Button variant="secondary" disabled={empanada.stock == 0}
                                    onClick={() => setDozen(Math.max(dozen - 1, 0))}>-</Button>
                            <Form.Control type="number" disabled={empanada.stock == 0} min="0" value={dozen} readOnly
                                          className="mx-2 text-center"/>
                            <Button variant="secondary" disabled={empanada.stock == 0}
                                    onClick={() => setDozen(dozen + 1)}>+</Button>
                        </div>
                    </Form.Group>
                )}
                <Form.Group controlId={`formUnits-${empanada.id}`}>
                    <Form.Label>Unidades</Form.Label>
                    <div className="d-flex align-items-center justify-content-between">
                        <Button variant="secondary" disabled={empanada.stock == 0}
                                onClick={() => setUnits(Math.max(units - 1, 0))}>-</Button>
                        <Form.Control type="number" disabled={empanada.stock == 0} min="0" value={units} readOnly
                                      className="mx-2 text-center"/>
                        <Button variant="secondary" disabled={empanada.stock == 0}
                                onClick={() => setUnits(units + 1)}>+</Button>
                    </div>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default OrderCard;