import React from "react";
import {Link} from "react-router-dom";
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import "./Header.css";

const Header = () => {
    return (
        <Navbar expand="lg" className="flex-column">
            <div className="d-flex align-items-center">
                <Navbar.Brand href="/">
                    <img className="logo" src={process.env.PUBLIC_URL + '/Logo.png'} alt="Entre Migas Y Repulgues"/>
                </Navbar.Brand>
                <h1 className="display-2 title">Entre Migas Y Repulgues</h1>
            </div>
            <Nav className="mr-auto bg-grey w-100 d-flex flex-row justify-content-evenly">
                <Nav.Link as={Link} to="/empanadas">Productos</Nav.Link>
                <Nav.Link as={Link} to="/pedidos">Pedidos</Nav.Link>
            </Nav>
        </Navbar>
    );
};

export default Header;